import { createContext, useState } from "react";
import { createUserByWallet } from "../services/user/user.services";
import { Spinner } from "../style";

export const MetamaskContext = createContext({});

export function MetamaskProvider({ children }) {
  const [walletId, setWalletId] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function getSessionId() {
    return localStorage.getItem("sessionId");
  }

  function getPlayfabId() {
    return localStorage.getItem("playfabId");
  }

  function logout() {
    localStorage.clear();
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }

  function getWalletId() {
    return localStorage.getItem('walletId');
  }

  async function fetchWallet() {
    if (!window.ethereum) {
      return window.open("https://metamask.io/download/", "_blank");
    }

    setLoading(true); // Exibir o spinner
    setError(null);

    try {
      const addresses = await window.ethereum.request({ method: "eth_requestAccounts" });
      setWalletId(addresses[0]);

      const assign = await createUserByWallet(addresses[0]);
      localStorage.setItem("playfabId", assign.data["play-fab-id"]);
      localStorage.setItem("sessionId", assign.data["session-id"]);
      localStorage.setItem("walletId", addresses[0]);

      setLoading(false); // Parar o carregamento
      document.querySelector("#step-button-1").click();
    } catch (error) {
      console.error("Erro ao conectar com MetaMask:", error);
      setError("Falha ao conectar com MetaMask. Tente novamente.");
      setLoading(false);
    }
  }

  return (
    <MetamaskContext.Provider
      value={{
        walletId,
        fetchWallet,
        getPlayfabId,
        getSessionId,
        getWalletId,
        logout,
        loading,
        setLoading,
        error
      }}
    >
      {/* Spinner será exibido enquanto loading for true */}
      {loading && 
      <Spinner/>}

      <div style={{ filter: loading ? 'blur(5px)' : 'none', pointerEvents: loading ? 'none' : 'auto' }}>
        {/* Renderizar o conteúdo normalmente, mas desfocado se loading for true */}
        {children}
      </div>
    </MetamaskContext.Provider>
  );
}