import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import imgInimigo06 from "../../assets/images/profile/ProfilePicture_Ilusão.png";
import imgInimigo01 from "../../assets/images/profile/ProfilePicture_Inimigo01.png";
import imgInimigo02 from "../../assets/images/profile/ProfilePicture_Inimigo02.png";
import imgInimigo03 from "../../assets/images/profile/ProfilePicture_Inimigo03.png";
import imgInimigo04 from "../../assets/images/profile/ProfilePicture_Inimigo04.png";
import imgInimigo05 from "../../assets/images/profile/ProfilePicture_Inimigo05.png";



import {
  AvatarImage,
  CloseButton,
  Container,
  EditButton,
  ImagePicker,
  ModalContent,
  PickerImage,
  StyledModal,
} from "./style";

const Avatar = ({callback}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(imgInimigo06);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const images = [imgInimigo01, imgInimigo02, imgInimigo03,imgInimigo06 , imgInimigo04, imgInimigo05];

  const handleImageClick = (image) => {
    setCurrentImage(image);
    closeModal();
  };
  useEffect(()=>{
   
   
    const regex = /ProfilePicture_Inimigo\d{2}|ProfilePicture_Ilusão/;
    
    const result = '/assets/images/profile/'+currentImage.match(regex)+".png";

    
    callback(result)
  },[currentImage])

  return (
    <Container>
      <AvatarImage src={currentImage} alt="Avatar" />
      <EditButton onClick={openModal}>
        <FaEdit />
      </EditButton>

      <StyledModal open={isOpen} onClose={closeModal}>
        <ModalContent>
          <h2>Select an Avatar</h2>
          <ImagePicker>
            {images.map((image, index) => (
              <PickerImage
                key={index}
                src={image}
                alt={`Avatar Option ${index}`}
                onClick={() => handleImageClick(image)}
              />
            ))}
          </ImagePicker>
          <CloseButton onClick={closeModal}>Close</CloseButton>
        </ModalContent>
      </StyledModal>
    </Container>
  );
};

export default Avatar;
