import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 6rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const Box = styled.div`

  .title{
    font-size: 18px;
    color: ${({ theme }) => theme.titleAccount};
  }

  .subtitle{
    font-size: 18px;
    color: ${({ theme }) => theme.subtitleAccount};
  }

  .headerBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h3 {
      text-align: center;
      font-size: 18px;
    }

    button {
      width: 250px;
      height: 50px;
      background-color: ${({ theme }) => theme.button};
      border: none;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colorButton};
      font-size: 15px;
      gap: 15px;
      font-weight: bold;
      cursor: pointer;
      transition: 0.5s;

      img {
        width: 35px;
      }
    }

    button:hover {
      
      background-color: ${({ theme }) => theme.buttonHover};;
    }
    @media (max-width: 768px) {
    width: 100%;
    
  }
  }

  ul {
    padding: 0;
    
    li {
      list-style-type: none;
      font-weight: 500;
      font-size: 0.8rem;
      margin-bottom: 0px;
      color: ${({ theme }) => theme.listTextAccount};
    }

    li a{
        color: #78c8e1;
        text-decoration: none;
        
    }
  }
`;
