export const lightTheme = {
  // css - App.js
  boxBackground: "#231429",
  buttonText: "#231429",
  buttonBackground: "#666666",
  lineColor: "#573861",
  titleColor: "#FDC959",
  subtitleColor: "#dddddd",
  borderColor: "#FDC959",
  componentsBackground: "#231429",
  // css - All
  button: "#FEC959",
  buttonHover: "#ffb116",
  colorButton: "#231229",
  // css - CreateAccount
  titleAccount: "#fff",
  subtitleAccount: "#FDC959",
  listTextAccount: "#fff",
  // css - CompleteProfile
  labelCompleteProfile: "#fff",
  TextCompleteProfile: "#fff",
};

export const darkTheme = {
  // css - App.js
  boxBackground: "#231429",
  buttonText: "#231429",
  buttonBackground: "#666666",
  lineColor: "#573861",
  titleColor: "#FDC959",
  subtitleColor: "#dddddd",
  borderColor: "#FDC959",
  componentsBackground: "#231429",
  // css - All
  button: "#FEC959",
  buttonHover: "#ffb116",
  colorButton: "#231229",
  // css - CreateAccount
  titleAccount: "#fff",
  subtitleAccount: "#FDC959",
  listTextAccount: "#fff",
  // css - CompleteProfile
  labelCompleteProfile: "#fff",
  TextCompleteProfile: "#fff",
};

/*
  // css - App.js
  boxBackground: "#ffffff",
  buttonText: "#fff",
  buttonBackground: "#78C8E1",
  lineColor: "#294258",
  titleColor: "#78C8E1",
  subtitleColor: "#000000",
  borderColor: "#78C8E1",
  componentsBackground: "#ffffff",
  // css - all
  button: "#78C8E1",
  buttonHover: "#294258",
  colorButton: "#fff",
  // css - CreateAccount
  titleAccount: "#000",
  subtitleAccount: "#000",
  listTextAccount: "#000",

  // css - CompleteProfile
  labelCompleteProfile: "#000",
  TextCompleteProfile: "#000"
*/
