import styled from "styled-components";

export const Container = styled.section`
 width: 100%;
 height: 100%;
 display: flex;
 justify-content: space-between;
 gap: 4rem;

 @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
  }
`;

export const Box = styled.div`
width: 50%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media (max-width: 768px) {
width   : 100%;

}

`;

export const Formulario = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    div{
        display: flex;
        flex-direction: column;

        label{
            font-size: 15px;
            font-weight: 500;
            color: ${({theme}) => theme.labelCompleteProfile};
        }

        sub{
            color: red;
            font-weight: 500;
            font-size: 13px;
        }
    }
`;

export const Input = styled.input`
    height: 25px;
    border-radius: 5px;
    border: 1px solid #639FB1;
    width: 250px;
    outline: none;
`;

export const ButtonSubmit = styled.button`
    width: 150px;
    border-radius: 5px;
    background-color: ${({theme}) => theme.button};
    border: none;
    height: 30px;
    font-weight: bold;
    font-size: 15px;
    color: ${({theme}) => theme.colorButton};
    cursor: pointer;
    transition: .5s;
    align-self: flex-end;

    &:hover{
        background-color: ${({theme}) => theme.buttonHover};
    }
    @media (max-width: 768px) {
    align-self: center;
  }
`;

export const Avatar = styled.div`
    width: 100px;
    height: 100px;
    background-color: #d9d9d9;
    border-radius: 5px;
`;

export const Text = styled.p`
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    color: ${({theme}) => theme.TextCompleteProfile}
    
`;