import clientPlayFab from "../clientPlayFab"
import clientV1 from "../clientV1"
import Web3 from "web3"
const web3 = new Web3()
async function createUserByWallet(walletId) {
    const res = await clientV1.get(`login/${walletId.substring(2)}`)
        .then(response => response)
        .catch(err => {
            console.error(err
            )
            return
        })

    if (!res) {
        return
    }
    const challenge = await window.ethereum.request({
        method: "personal_sign",
        params: [web3.utils.stringToHex(res.data.challenge), walletId]
    }).then((hash) => {
        return hash
    }).catch((exeption) => {

        console.error(exeption)
        return
    })

    if (!challenge) {

        return
    }

    const assign = await clientV1.post(`login/${walletId.substring(2)}`, { challenge })
        .then(response => response)
        .catch(err => {
            console.error(err
            )
            return
        })
    return assign


}
async function updateUser(user, key, data) {

    const response = await clientV1.post(`/user/${user}/${key}/`, key == 'avatar' ? { 'avatar-url': "."+data } : { 'display-name': data })
    return response
}

async function getUser(playfabId, sessionId) {
    const info = {
        name: null,
        avatar: null,
        balance: null,
        playfabId,
        sessionId,
    }


    const userInfo = await clientPlayFab.post('/Client/GetAccountInfo',
        {
            PlayFabId: playfabId,
        }, {
        headers: {
            "X-Authorization": sessionId
        }
    }).then((response) => {
        return response.data.data
    }).catch((err) => {
        console.log(err)

        return null
    })

    if (!userInfo) {
        return
    }
    info.mail = userInfo.AccountInfo.PrivateInfo.Email
    const customId = userInfo.AccountInfo.CustomIdInfo.CustomId

    info.wallet = await clientV1.get(`/lbl/playfab/v1/user/${customId}/walletId/`).then((res) => {
        console.log(res)

        return res.data.customId

    }).catch((res) => { console.log(res) })
   


    await clientV1.get(`/lbl/playfab/v1/user/${info.wallet}/display-name/`).then((res) => {
        info.name = res.data["display-name"]

    }).catch((err) => {
        info.name=userInfo.AccountInfo.TitleInfo.DisplayName
    })

    await clientV1.get(`/lbl/playfab/v1/user/${info.wallet}/avatar/`).then((res) => {
        info.avatar = res.data["avatar-url"]
    }).catch((err) => {
        console.log(err)
    })

   
    return info

}

async function updateMailAndPassword(Email,Password,UserName,sessionId) {


    return await clientPlayFab.post('/Client/AddUsernamePassword', {
        Email,
        Password,
        UserName
    }, {
        headers: {
            "X-Authorization": sessionId
        }
    })

}


export {
    updateUser,
    getUser,
    createUserByWallet,
    updateMailAndPassword,
}