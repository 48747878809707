import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: start;
  justify-content: center;
  gap: 4rem;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 
  .mark {
    background-color: red;
    width: 100%;
    height: 8rem;
  }

  div {
    display: flex;
    justify-content: space-around;
    min-width: 200px;
    margin-top: 1rem;
  }

  div button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: none;
    font-size: 0.8rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-weight: 500;
  color: ${({ theme }) => theme.TextCompleteProfile};
  font-size: 0.9rem;
  line-height: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis; 
  max-height: 3.2rem;
  text-align: center;
  font-weight: 500;
  margin: 0;
`;
