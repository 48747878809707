import styled from "styled-components";

export const Container = styled.main`
  
  display: flex;
  align-items: center;
  justify-content: center;
overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const SpinnerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const SpinnerCircle = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid lightgray;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner = () => (
  <SpinnerWrapper>
    <SpinnerCircle />
  </SpinnerWrapper>
);

export const Box = styled.section`
  background-color: ${({ theme }) => theme.boxBackground};
  width: 75em;
  height: 600px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding: 20px 30px;
  margin-top: 100px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 20px;
    gap: 1.5rem;
    margin: 0;
    padding-bottom: 0;
    padding-top: 22px;
    
  }
`;

export const HeaderBoxSteps = styled.div`
  .control-steps {
    display: flex;
    width: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .control-steps button {
    width: 70px;
    height: 70px;
    font-size: 32px;
    border-radius: 10px;
    color: ${({ theme }) => theme.buttonText};
    background-color: ${({ theme }) => theme.buttonBackground};
    z-index: 2;
    border: none;
    background-color: #00000000;
    cursor: pointer;
    font-weight: bold;
    font-size: 40px;
  }

  .line-control {
    width: 600px;
    min-height: 1px;
    border-bottom: 4px dashed ${({ theme }) => theme.lineColor};
    display: block;
    position: absolute;
    top: 12px;
    left: 150px;
    @media (max-width: 768px) {
    
   display: none;
    
  }
  }

  .button-control-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    width: 200px;
  }

  .button-control-info span {
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 5px;
    color: ${({ theme }) => theme.buttonText};
  }

  @media (max-width: 768px) {
    .line-control {
      width: 100px;
    }

    .control-steps button {
      width: 40px;
      height: 40px;
      font-size: 24px;
      margin: 0;
    }

    
    .button-control-info {
      width: 100%;
     
    }
    .button-control-info span {
    font-weight: bold;
    font-size: 9px;
  
  }
  }

`;

export const BodyBox = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    gap: 1.5rem;
  }
`;

export const Title = styled.h2`
  text-align: center;
  margin: 0;
  color: ${({ theme }) => theme.titleColor};
  font-size: 36px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

export const Subtitle = styled.h3`
  text-align: center;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.subtitleColor};

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const ComponentsContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.borderColor};
  height: 17rem;
  align-items: center;
  border-radius: 10px;
  padding: 15px 60px;

  background-color: ${({ theme }) => theme.componentsBackground};
 
  @media (max-width: 768px) {
    
    height: auto;
  }
`;
