import React from 'react'
import { Button } from './style'

function SocialButton({title, color}) {
  return (
    <Button color={color}>
        {title}
    </Button>
  )
}

export default SocialButton;