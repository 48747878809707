import styled from "styled-components";

export const Button = styled.button`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: ${(props) => props.color};
    border: none;
    cursor: pointer;
    transition: .5s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        opacity: 0.6;
    }
`;