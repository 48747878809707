import styled from "styled-components";

export const LinkAnchor = styled.a`
    font-weight: 500;
    color: #78C8E1;
    transition: .5s;

    &:hover{
        color: #294258;
    }
`;