import { useContext, useEffect, useState } from "react";
import logoMetamask from "../../assets/images/metamask.png";
import { MetamaskContext } from "../../context/metamaskContext";


export default function () {
    const { fetchWallet, getPlayfabId,logout } = useContext(MetamaskContext)
const [playfabId,setPlayfabId]=useState()
useEffect(()=>{
    const playfab = getPlayfabId()
    setPlayfabId(playfab)
   /* if(playfab){
        document.querySelector('#step-button-1').click()
    }*/

},[])

    return <>
        {playfabId ? <button onClick={()=>{
           logout()
           
        }}>    <img src={logoMetamask} alt="metamask logos" /> disconnect </button> : <button onClick={() => fetchWallet()} >
            <img src={logoMetamask} alt="metamask logos" />
            Sign in with Metamask
        </button>
        }  </>
}