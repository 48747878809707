import React from "react";
import { Box, Container } from "./style";
import img from "../../assets/images/join.png";
import logoMetamask from "../../assets/images/metamask.png";
import Link from "../Link";
import ConnectButton from "../metamask/connectButton";

function CreateAccount() {
  return (
    <Container>
      <div>
        <img src={img} alt="plushie" width="300px" />
      </div>
      <Box>
        <div className="headerBox">
          <h3 className="title">
            Sign in with Metamask to create an account.
          </h3>
         <ConnectButton/>
        </div>
        <div>
          <h3 className="subtitle">Don't have a Metamask wallet?</h3>
          <ul>
            <li>
              Plushie Guardians is a blockchain game,
            </li>
            <li>
              which means your assets are always yours!  
            </li>
            <li>
              But it also means you will need a Metamask wallet to sign up.
            </li>
            <li>
              If you don't have it yet, you can create it <Link href={"https://metamask.io/download/"} title="here" /> to get started.
            </li>
          </ul>
        </div>
      </Box>
    </Container>
  );
}

export default CreateAccount;
