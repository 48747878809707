import { useContext } from "react"
import { MetamaskContext } from "../context/metamaskContext"

export default function () {

   const{loading}= useContext(MetamaskContext)
  return (
    <div className={loading?"load-widow":"normal"}>
      <img src="" alt="" />
    </div>
  )
}
