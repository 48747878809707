import styled from "styled-components";
import { Modal } from "@mui/material";

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const AvatarImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

export const EditButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  & .MuiModal-backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const ModalContent = styled.div`
  background: #231429;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  margin: auto;

  h2{
    text-align: center;
    color: #FDC959;
  }
`;

export const ImagePicker = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0px 20px 0px;
`;

export const PickerImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  margin-top: 10px;
  padding: 10px;
  border: none;
  background-color: #FDC959;
  color: #231429;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
`;
