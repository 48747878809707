import React from "react";
import { Container, Box, Text } from "./style";
import Link from "../../components/Link";
import SocialButton from "../SocialButton";
import { FaDiscord, FaInstagram, FaTelegramPlane  } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import image1 from "../../assets/images/comp3-1.png";
import image2 from "../../assets/images/comp3-2.png";
import image3 from "../../assets/images/img-discord.png";



const icons = [
  {
    icon: <FaDiscord size="25px" color="#fff" />,
    color: "#516be7",
    link: "https://discord.gg/plushieguardians"
  },
  {
    icon: <FaXTwitter size="25px" color="#fff" />,
    color: "#3CBBFF",
    link: "https://twitter.com/plushieofficial"
  },
  {
    icon: <FaInstagram size="25px" color="#fff" />,
    color: "linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7)",
    link: "https://www.instagram.com/plushieguardians/"
  },
  {
    icon: <FaTelegramPlane size="25px" color="#fff" />,
    color: "#088CCE",
    link: "https://t.me/plushieguardians"
  },
];

function PlayGame() {
  return (
    <Container>
      <Box>
        <Text>Two new random plushies to play every week.</Text>
        <img src={image1} alt="image" width={'180px'}/>
        <Text>
          But you can also mint your own plushies <Link title="here" href="https://marketplace.plushieguardians.com/mint.html" target="_blank" />
          .
        </Text>
      </Box>
      <Box>
        <Text>Beat players to earn tokens and craft new equipment.</Text>
        <img src={image2} alt="image" width={'280px'}/>
      </Box>
      <Box>
        <Text>Join the Community for tips, news and exclusive rewards.</Text>
        <img src={image3} alt="image" width={'280px'}/>
        <div>
          {icons.map((item) => (
            <a href={item.link} target="_blank" rel="noreferrer">
              <SocialButton
              key={item.icon}
              title={item.icon}
              color={item.color}
            />
            </a>
          ))}
        </div>
      </Box>
    </Container>
  );
}

export default PlayGame;
