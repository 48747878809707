import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./theme/theme";
import {
  Container,
  Box,
  HeaderBoxSteps,
  BodyBox,
  Title,
  Subtitle,
  ComponentsContainer,
  Spinner,
} from "./style";
import { steps } from "./util/util";
import { Switch } from "@mui/material";
import { BsCaretDownFill } from "react-icons/bs";
import { MetamaskProvider } from "./context/metamaskContext";
import LoadWidow from "./components/loadWidow";
import { Helmet } from 'react-helmet';


function App() {
  const [currentStep, setCurrentStep] = useState(0);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const { component, title, subtitle } = steps[currentStep];

  if (loading) {
    return <Spinner />;
  }

  return (
    <MetamaskProvider>
     
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <Container>
        <Helmet>

<script>
  {`!function (f, b, e, v, n, t, s) {
if (f.fbq) return; n = f.fbq = function () {
n.callMethod ?
n.callMethod.apply(n, arguments) : n.queue.push(arguments)
};
if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
n.queue = []; t = b.createElement(e); t.async = !0;
t.src = v; s = b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t, s)
}(window, document, 'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1129271181419182');
fbq('track', 'PageView');`}
</script>


<script async src="https://www.googletagmanager.com/gtag/js?id=AW-16723057262"></script>
<script>
  {`  window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments) }
gtag('js', new Date());

gtag('config', 'AW-16723057262');`}
</script>

<script>
  {`(function (w, d, s, l, i) {
w[l] = w[l] || []; w[l].push({
'gtm.start':
  new Date().getTime(), event: 'gtm.js'
}); var f = d.getElementsByTagName(s)[0],
j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
  'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-K9XXP545');`}

</script>

</Helmet>
<noscript><img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=1129271181419182&ev=PageView&noscript=1" /></noscript>
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K9XXP545"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
          <Box>
            <div style={{ display: "none" }}>
              <Switch checked={true} color="default" />
            </div>
            <HeaderBoxSteps>
              <div className="control-steps">
                <div className="line-control">.</div>
                {steps.map((step, index) => (
                  <div
                    style={{ position: "relative" }}
                    className="button-control-info "
                    key={index}
                  >
                    <div style={{ position: "absolute", top: -30 }}>
                      <BsCaretDownFill
                        size={"30px"}
                        style={{
                          display: currentStep === index ? "block" : "none",
                        }}
                        color={"#FDC959"}
                      />
                    </div>
                    <button
                      id={"step-button-" + index}
                      onClick={() => setCurrentStep(index)}
                      style={{
                        backgroundImage:
                          currentStep === index
                            ? step.selected
                            : step.default,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        border: "none",
                      }}
                    >
                      {index === 3 ? "" : index + 1}
                    </button>
                    <span
                      style={{
                        color: "#fff",
                      }}
                    >
                      {step.title}
                    </span>
                  </div>
                ))}
              </div>
            </HeaderBoxSteps>
            <BodyBox>
              <div>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
              </div>
              <ComponentsContainer>{component}</ComponentsContainer>
            </BodyBox>
          </Box>
        </Container>
        <LoadWidow />
      </ThemeProvider>
    </MetamaskProvider>
  );
}

export default App;
