import React, { useState } from "react";
import { Box, Button, Container, Title } from "./style";
import { AiOutlineLoading } from "react-icons/ai";
import { FaWindows } from "react-icons/fa";

function DownloadGame() {
  const [onLoad, setOnload] = useState(false);

  function downloadLaucher() {
    // eslint-disable-next-line no-restricted-globals
    location.href = 'https://marketplace.plushieguardians.com/assets/data/PlushieGuardiansInstaller.msi'
  }

  function nextStep() {
    document.getElementById('step-button-2').click()
  }

  return (
    <Container>
      <Box>
        <Title>
          It is time to download <br></br> the game launcher.
        </Title>
        <Button onClick={downloadLaucher}><FaWindows /> Download Plushie Guardians</Button>
      </Box>
      <Box>
        {!onLoad && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
            }}
          >
            <AiOutlineLoading
              size={"65px"}
              color="#d9d9d9"
              className="spin"
            />
          </div>
        )}
        <iframe
          title="Plushie"
          loop
          muted
          src="https://www.youtube.com/embed/frCEZQBMsXk?autoplay=1&mute=1&loop=1&playlist=frCEZQBMsXk"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameborder="0"
          onLoad={() => setOnload(true)}
          style={{ display: onLoad ? "block" : "none" }}
          className="video-landing"
        ></iframe>
      </Box>
      <Box>
        <Title>
          While you wait, go ahead to <br></br> finish your game profile.
        </Title>
        <Button onClick={nextStep}>Next Step</Button>
      </Box>
    </Container>
  );
}

export default DownloadGame;
