import React from "react";
import DownloadGame from "../components/DownloadGame";
import CompleteProfile from "../components/CompleteProfile";
import PlayGame from "../components/PlayGame";
import CreateAccount from "../components/CreateAccount";

const getSvgDataUrl = (color) => {
  return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="85" height="92" viewBox="0 0 85 92" fill="none"><path fill="${encodeURIComponent(
    color
  )}" d="M74 14.825L68.5 11.625L54.7 19.425H53.5L59.4 6.925L59.8 6.625L53.4 2.925C46.6 -0.975 38.3 -0.975 31.6 2.925L10.9 14.825C4.2 18.725 0 25.925 0 33.725V57.525C0 64.125 3 70.225 7.9 74.325C9.6 74.025 14.5 73.025 15.7 72.325C14 74.425 12.8 75.825 11.9 76.925L31.6 88.325C38.4 92.225 46.7 92.225 53.4 88.325L61.7 83.525C63.2 80.425 66.3 74.225 68.6 72.325C68.6 74.225 68.5 76.125 69.1 79.225L74.1 76.325C80.9 72.425 85 65.225 85 57.425V33.725C85 25.925 80.8 18.725 74 14.825Z"/></svg>')`;
};

const getPlayButtonUrl = (color) => {
  return `url('data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
      <svg width="74" height="98" viewBox="0 0 74 98" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M67.6127 7.97397C60.5032 1.23999 52.1614 -0.46721 30.738 0.101858C24.1973 0.291548 17.3722 0.576082 13.0117 1.05031C11.021 1.23999 9.88347 2.28329 9.40951 3.8008C4.29066 21.2522 1.63644 40.316 0.593715 57.0087C-0.449014 72.4684 -0.0698396 86.2208 1.35206 95.4208C1.73124 97.8867 3.15314 98.0764 4.95421 97.9816C9.03033 97.6022 13.77 97.128 18.4149 96.5589C24.1973 95.8002 29.8849 94.9465 33.7714 94.2826C35.3829 93.9981 36.3308 93.2393 36.4256 91.9115C36.5204 90.2992 34.3402 85.1776 34.6246 81.0044C34.9089 76.5467 38.0371 73.6065 45.8102 69.2436C57.5646 62.32 72.8263 52.1716 73.9638 29.5986C74.2482 20.3986 72.9211 12.9059 67.6127 7.97397ZM38.7955 46.7654C38.2267 46.3861 37.7528 46.1015 37.2788 45.7222C24.8608 36.8068 30.8328 29.0295 31.0224 28.8398C33.7714 24.9512 41.1653 21.7264 42.208 23.9079C42.4924 24.5718 42.1132 25.3305 41.4497 25.7099C34.9089 28.745 39.3642 35.4789 45.0518 34.9099C45.905 34.815 46.6633 35.0047 47.2321 35.4789C51.3082 38.7037 44.4831 49.5159 38.7955 46.7654Z" fill="${color}"/>
      </svg>`)}`;
};

const step1 = (color) => {
  return `url('data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
      <svg width="85" height="92" viewBox="0 0 85 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M74 14.925L70 12.525C66.7 14.625 63.1 17.625 63.1 17.625C63.1 17.625 61.9 14.825 60.8 13.025C60 14.025 53.9 21.425 54.4 19.925C54.9 18.425 56.3 13.625 57.4 11.025C57.8 10.025 58.6 8.425 59.7 6.825L53.4 2.925C46.6 -0.975 38.3 -0.975 31.6 2.925L10.9 14.925C4.2 18.825 0 26.025 0 33.825V57.625C0 64.725 3.5 71.425 9.2 75.425C11.4 73.825 15.7 70.525 17.5 69.925C15.5 74.125 14 76.125 13.2 77.825L31.5 88.325C38.3 92.225 46.6 92.225 53.3 88.325L74 76.525C80.8 72.625 85 65.425 85 57.625V33.825C85 26.025 80.8 18.825 74 14.925Z" fill="${color}"/>
      </svg>`)}
      `;
};

const step2 = (color) => {
  return `url('data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
      <svg width="85" height="92" viewBox="0 0 85 92" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M74 14.925L53.4 2.925C46.6 -0.975 38.3 -0.975 31.6 2.925L25.1 6.625C25 8.825 25.2 12.925 25.8 18.425C24.6 17.425 20.2 11.625 19.1 10.125L11 14.825C4.2 18.825 0 26.025 0 33.825V57.625C0 65.425 4.2 72.625 10.9 76.525L31.5 88.425C38.3 92.325 46.6 92.325 53.3 88.425L54.5 87.725L58.6 80.725L61.5 82.225L61.8 77.925C61.8 77.925 66.3 79.925 67.4 80.325L74.1 76.525C80.9 72.625 85 65.425 85 57.625V33.825C85 26.025 80.8 18.825 74 14.925Z" fill="${color}"/>
</svg>`)}
      `;
};

export const steps = [
  {
    selected: step1("#FDC959"),
    default: step1("#573861"),
    component: <CreateAccount />,
    title: "Set up your account",
    subtitle: "Battle plushies around the world for rewards!",
  },
  {
    selected: step2("#FDC959"),
    default: step2("#573861"),
    component: <DownloadGame />,
    title: "Download the game launcher",
    subtitle:
      "Now that you have an active account, you are one step closer to face some plushies!",
  },
  {
    selected: getSvgDataUrl("#FDC959"),
    default: getSvgDataUrl("#573861"),
    component: <CompleteProfile />,
    title: "Complete Profile",
    subtitle:
      "In order to login and battle, you must also set up an e-mail and password.",
  },
  {
    selected: getPlayButtonUrl("#FDC959"),
    default: getPlayButtonUrl("#fff"),
    component: <PlayGame />,
    title: "Play the Game!",
    subtitle: "Assemble your team, enter the arena, and earn materials.",
  },
];
