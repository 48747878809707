import React, { useContext, useEffect, useState } from "react";
import { Box, ButtonSubmit, Container, Formulario, Input, Text } from "./style";
import Avatar from "../Avatar";
import { MetamaskContext } from "../../context/metamaskContext";
import { getUser, updateMailAndPassword, updateUser } from "../../services/user/user.services";
import { useForm } from "react-hook-form";
import SweetAlert2 from 'react-sweetalert2';


function CompleteProfile() {

  const { getPlayfabId, getSessionId, getWalletId, setLoading } = useContext(MetamaskContext)

  const { register, handleSubmit, formState: { errors }, watch } = useForm();

  const [swalProps, setSwalProps] = useState({});

  const [avatar, setAvatar] = useState();
  const [user, setUser] = useState({ mail: null })
  useEffect(() => {
    fetchUser()
    if (!getPlayfabId()) {
      document.querySelector("#step-button-0").click()
    }
  }, [])
  async function fetchUser() {
    const userReq = await getUser(getPlayfabId(), getSessionId())
    setUser(userReq)
  }
  const password = watch("password");

  const onSubmitFormOne = (data) => {
    const { email, password } = data
    setLoading(true)
    setTimeout(()=>{
      updateMailAndPassword(email, password, user.name.replace('#', ''), getSessionId())
      .then(() => {
        setLoading(false)
        fetchUser()})
        .catch(setLoading(false))

      setLoading(false)
    },3000)
   





  };
  const onSubmitFormTwo = (data) => {

    const wallet = getWalletId().substring(2)
    setLoading(true)
    if (avatar) {
      updateUser(wallet, 'avatar', avatar).then(() => {
        setLoading(false)
     
      }).catch((err)=>{
        console.error(err)
        setLoading(false)
      })

    }
    if (data.name && data.name != user.name) {

      updateUser(wallet, 'display-name', data.name).then(() => {
        setLoading(false)
        document.querySelector("#step-button-3").click()
      }).catch((err)=>{
        
        setLoading(false)
        const {status}=err
        if(status==400){
          setSwalProps({text:"This username is already in use!",show: true})
          setTimeout(()=>{
            setSwalProps({show: false})

          },3000)
        }
      })
    }


  };

  return (
    <Container>
      {getPlayfabId() ? <><Box>
        {!user?.mail ? <Formulario onSubmit={handleSubmit(onSubmitFormOne)}>
          <div>
            <label>E-mail</label>
            <Input type="email"  {...register("email", { required: "Mandatory field", pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/ })} />


            {errors.email && <span className="error-message">{errors.email.message}</span>}
          </div>
          <div>
            <label>Password</label>
            <Input  {...register("password", {
              required: "Mandatory field",
              minLength: { value: 6, message: "The password must be at least 6 characters long" },
              pattern: {
                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/,
                message: "The password must have an upper case letter, a lower case one, as well as a number"
              }
            })} />

            {errors.password && <span className="error-message">{errors.password.message}</span>}
          </div>
          <div>
            <label>Repeat Password</label>
            <Input {...register("repeatPassword", {
              required: "Mandatory field",
              validate: (value) => value === password || "Passwords do not match"
            })} />

            {errors.repeatPassword && <span className="error-message">{errors.repeatPassword.message}</span>}
          </div>


          <ButtonSubmit>Submit</ButtonSubmit>
        </Formulario> : <>
          <div className="warn-success">
            Your email and password have been registered!
          </div>

        </>}
      </Box>
        <Box>
          <Text>
            You may choose an avatar and a display name you want people to see.
          </Text>
          <Avatar callback={setAvatar} />
          <Formulario onSubmit={handleSubmit(onSubmitFormTwo)}>
            <div>
              <label>Nickname</label>
              <Input {...register("name")} defaultValue={user?.name} />

            </div>
            {user?.mail ? <ButtonSubmit >Update</ButtonSubmit> : <></>}
          </Formulario>
        </Box></> : <>
      </>}
      <SweetAlert2 {...swalProps}/>
    </Container>
  );
}

export default CompleteProfile;
