import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .spin {
    animation: spin 1s linear infinite;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    
    gap: 0;
  }
  
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

 
  iframe{
  height: 170px;
    transform: scale(1.2);
    border-radius: 10px;
  }
  

`;

export const Title = styled.h3`
  text-align: center;
  font-size: 18px;
  color: ${({ theme }) => theme.titleAccount};
`;

export const Button = styled.button`
  width: 16rem;
  height: 40px;
  background-color: ${({ theme }) => theme.button};
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colorButton};
  font-size: 15px;
  gap: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background-color: ${({ theme }) => theme.buttonHover};
  }
`;
